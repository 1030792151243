// Texts and links
a {
  @apply transition-colors text-primary-main;

  &:hover {
    @apply text-primary-dark;
  }
}

// Background colors
.kanpla-bg-primary {
  @apply bg-background-primary transition-colors;
}

.kanpla-bg-primary.hoverable:hover {
  @apply bg-background-secondary;
}

.kanpla-border-dark {
  @apply border-primary-dark;
}

/* ANT design colors */
.ant-btn:not(:disabled):not(.nobg) {
  background: none;
  @apply transition-colors #{!important};
}

.ant-btn.ant-btn-primary:not(:disabled):not(.nobg) {
  @apply bg-primary-main text-primary-contrast border-primary-dark border #{!important};

  &:hover,
  &:focus {
    @apply bg-primary-dark #{!important};
  }

  &:active {
    @apply bg-primary-light #{!important};
  }
}

.ant-btn:not(:disabled):not(.nobg) {
  @apply bg-secondary-main text-secondary-contrast border-secondary-dark border #{!important};

  &:hover,
  &:focus {
    @apply bg-secondary-dark #{!important};
  }

  &:active {
    @apply bg-secondary-light #{!important};
  }
}

.ant-btn.ant-btn-dangerous:not(:disabled):not(.nobg) {
  @apply bg-danger-main text-danger-contrast border-danger-dark border #{!important};

  &:hover,
  &:focus {
    @apply bg-danger-dark #{!important};
  }

  &:active {
    @apply bg-danger-light #{!important};
  }
}

.ant-btn-link,
.ant-btn-link:not(:disabled):not(.nobg) {
  @apply text-primary-main bg-transparent #{!important};
}

.ant-btn-link:hover,
.ant-btn-link:focus {
  @apply text-primary-dark #{!important};
}

.ant-btn-link,
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-text,
.ant-btn-text:hover,
.ant-btn-text:focus,
.ant-btn-link:not(:disabled):not(.nobg),
.ant-btn-link:not(:disabled):not(.nobg):hover,
.ant-btn-link:not(:disabled):not(.nobg):focus,
.ant-btn-text:not(:disabled):not(.nobg),
.ant-btn-text:not(:disabled):not(.nobg):hover,
.ant-btn-text:not(:disabled):not(.nobg):focus {
  @apply bg-transparent text-current border-none #{!important};
}

.ant-btn-text,
.ant-btn-text:hover,
.ant-btn-text:focus {
  @apply text-current #{!important};
}

.ant-btn-link:hover,
.ant-btn-link:focus {
  @apply text-primary-dark #{!important};
}

/** 
 * Fix for weird scrolling issue for Safari
 * https://www.notion.so/kanpla/Cannot-scroll-da298384bad94295ab7ef06084113788
 */
.rc-virtual-list-holder {
  scroll-behavior: initial !important;
}

.ant-picker,
.ant-select-selector {
  @apply border-divider-main;
}

.ant-picker:hover,
.ant-picker:active,
.ant-select:not(.ant-select-disabled) .ant-select-selector:hover,
.ant-select:not(.ant-select-disabled) .ant-select-selector:active {
  @apply border-primary-main;
}
.ant-picker-suffix,
.ant-picker-suffix > *,
.ant-select-suffix,
.ant-form-item-label > label,
.ant-steps-item-title,
.ant-checkbox-wrapper {
  @apply text-text-primary;
}

.ant-form-item-label > label {
  @apply w-full;
}

.ant-picker-large .ant-picker-input > input,
.ant-input-number-input-wrap > input {
  @apply text-gray-900;
}

.ant-select-disabled .ant-select-selector {
  @apply text-text-disabled;
}

.ant-btn:disabled {
  @apply filter grayscale;
}

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  @apply bg-primary-main text-primary-contrast;
}

/** ANT design step */
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  @apply text-text-secondary;
}

.ant-steps-item-wait .ant-steps-item-icon {
  @apply bg-background-primary border border-divider-main;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  @apply bg-divider-main;
}

.ant-card {
  @apply border #{!important};
}

.ant-switch {
  @apply bg-background-secondary;

  &.ant-switch-checked {
    @apply bg-primary-dark;
  }
}

/** ANT Design custom calendar */
.branded-calendar {
  .ant-picker-date-panel {
    @apply max-w-full w-full #{!important};
  }

  .ant-picker-panel-container .ant-picker-panel {
    @apply bg-background-secondary max-w-full #{!important};

    .ant-picker-header {
      @apply border-b border-divider-main;

      button:hover {
        @apply text-primary-main;
      }
    }

    .ant-picker-body {
      @apply rounded-b-lg overflow-hidden #{!important};
    }

    .ant-picker-header,
    .ant-picker-body {
      &,
      thead,
      tbody {
        th {
          @apply text-text-secondary #{!important};
        }

        &,
        td {
          @apply text-text-primary #{!important};

          .ant-picker-cell-inner {
            @apply transition-colors;
          }

          .ant-picker-cell {
            &:hover {
              .ant-picker-cell-inner {
                @apply bg-transparent text-primary-main #{!important};
              }
            }

            &.ant-picker-cell-selected {
              .ant-picker-cell-inner {
                @apply bg-primary-main text-primary-contrast #{!important};
              }

              &:hover {
                .ant-picker-cell-inner {
                  @apply bg-primary-dark #{!important};
                }
              }
            }
          }

          &.ant-picker-cell-disabled {
            @apply text-text-disabled opacity-40 #{!important};
          }
        }
      }
    }
  }
}

.ant-badge.custom-badge {
  &.primary .ant-badge-count {
    @apply bg-primary-main text-primary-contrast ring-1 ring-background-primary #{!important};
  }
  &.secondary .ant-badge-count {
    @apply bg-secondary-main text-secondary-contrast ring-1 ring-background-primary #{!important};
  }

  &.primary .ant-badge-dot {
    @apply bg-primary-main ring-1 ring-background-primary #{!important};
  }
  &.secondary .ant-badge-dot {
    @apply bg-secondary-main ring-1 ring-background-primary #{!important};
  }
}

$alertColors: (
  "info": "info",
  "success": "success",
  "warning": "warning",
  "error": "danger",
);

.ant-alert {
  &:not(.ant-alert-no-icon) {
    .ant-alert-message {
      @apply pl-8;
    }
  }

  @each $name, $value in $alertColors {
    &.ant-alert-#{$name} {
      @apply border-#{$value}-dark overflow-hidden bg-transparent;
      @apply before:bg-#{$value}-light before:block before:absolute before:inset-0 before:-z-10 before:opacity-10;

      .ant-alert-message {
        @apply text-#{$value}-dark;
      }

      span.anticon {
        @apply -ml-4 mr-4 text-#{$value}-contrast bg-#{$value}-dark h-full block p-2.5 absolute;
      }
    }
  }
}

.ant-message .ant-message-notice-content {
  @apply p-0 max-w-md w-fit;

  .ant-message-custom-content {
    @apply flex items-center;
  }

  @each $name, $value in $alertColors {
    .ant-message-#{$name} {
      @apply rounded-lg;
      @apply border border-#{$value}-dark;

      .ant-alert-message {
        @apply text-#{$value}-dark;
      }

      span.anticon {
        @apply text-#{$value}-contrast bg-#{$value}-dark static mr-0;
        padding: 0.8rem;
        top: 0;
        border-radius: 0.4rem 0 0 0.4rem;
        align-self: stretch;
      }

      span:not(.anticon) {
        @apply text-left;
        padding: 0.9rem;
      }
    }
  }

  .ant-message-loading {
    @apply p-3 px-4 border border-primary-dark rounded-lg;

    .ant-alert-message {
      @apply text-primary-dark;
    }

    span:not(.anticon) {
      @apply text-left;
    }
  }
}

/** Extra classes */
.form-label {
  @apply text-text-primary;
}

::placeholder,
.form-input::placeholder,
.form-textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  @apply text-divider-main;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  @apply text-divider-main;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  @apply text-divider-main;
}

input,
.form-input {
  @apply border-divider-main border-opacity-50 text-text-primary bg-background-primary;
}

.loader {
  @apply bg-gradient-to-br from-background-primary to-background-secondary;
}

.section {
  @apply bg-background-primary border-primary-light border;
}

.react-code-input {
  display: grid !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}

a {
  &:focus,
  &:active {
    @apply no-underline #{!important};
  }

  img {
    @apply transform transition-all #{!important};
  }

  &:hover {
    img {
      @apply scale-110;
    }
  }
}

.text-area-options {
  @apply leading-relaxed;

  span.highlight {
    @apply p-0.5 px-1 border rounded-md bg-background-secondary text-sm;
  }

  .public-DraftEditor-content {
    *[data-block="true"] {
      float: left;

      .enter {
        width: 100vw;
        display: block;
      }
    }
  }

  .DraftEditor-root,
  .DraftEditor-editorContainer,
  .public-DraftEditor-content,
  div[data-contents="true"] {
    @apply h-full;
  }
}

/** LOGO */
.logo-default {
  .primary,
  .secondary {
    @apply fill-text-primary;
  }
  &:not(.monotone) .secondary {
    @apply fill-primary-main;
  }
}

.logo-inverted {
  .primary,
  .secondary {
    @apply fill-background-primary;
  }
  &:not(.monotone) .secondary {
    @apply fill-primary-main;
  }
}

.logo-white {
  .primary,
  .secondary {
    @apply fill-white;
  }
  &:not(.monotone) .secondary {
    @apply fill-white;
  }
}

.ant-picker-input > input[disabled] {
  background: #edf2f7 !important;
}

/** Customization libs/ui/.../ProductBankInput.tsx modal */
.choose-group-modal {
  .selected-node {
    .anticon {
      @apply text-primary-main;
    }
  }
  .ant-tree-list {
    .ant-tree-switcher {
      line-height: 44px;
    }
  }
}

/** Customization libs/ui/.../ScopeInput.tsx modal */
.choose-school-modal {
  .ant-tree-list {
    .ant-tree-checkbox {
      @apply mt-2 mr-2;
    }
    .ant-tree-switcher {
      line-height: 32px;
    }
  }
}

/** Customization Tree component */
.choose-group-modal,
.choose-school-modal {
  .ant-tree {
    .ant-tree-node-content-wrapper {
      @apply p-1;
    }
    .ant-tree-node-content-wrapper.ant-tree-node-selected {
      @apply bg-transparent;
    }
  }
}

.tree-scope-input {
  span[title] {
    @apply w-full;
  }
}

/** Customization  */
.custom-tree {
  .ant-tree-switcher {
    line-height: 44px;
  }
  .ant-tree-node-content-wrapper {
    @apply p-1;
  }
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    @apply bg-transparent;
  }
}

/** Truncate words */
.truncate-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

/** Anonymous utilities */
.translate-bottom {
  top: 100%;
  transform: translate(0, -100%);
}

.bg-white-opacity-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 20%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.9) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.ant-popover-content {
  .ant-popover-arrow {
    .ant-popover-arrow-content {
      @apply bg-primary-main;
    }
    .ant-popover-arrow-content::before {
      background: var(--palette-primary-main);
    }
  }
  #popoverAuthentication {
    .ant-popover-inner-content {
      @apply p-0;
    }
  }
}

.authentication-button {
  .vertical-line {
    border-width: 1px;
    border-color: var(--palette-text-primary);
  }
}
